import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multiselect = _resolveComponent("multiselect")!

  return (_ctx.data.isLoaded)
    ? (_openBlock(), _createBlock(_component_multiselect, {
        key: 0,
        class: _normalizeClass([_ctx.classes, "ab-input"]),
        valueProp: "stateCode",
        label: "stateCode",
        modelValue: _ctx.data.state,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.state) = $event)),
        options: _ctx.data.states,
        "internal-search": "true",
        searchable: "true",
        style: {"min-width":"150px"},
        onSelect: _ctx.onChange,
        onClear: _ctx.onClear,
        disabled: _ctx.disabled
      }, null, 8, ["class", "modelValue", "options", "onSelect", "onClear", "disabled"]))
    : _createCommentVNode("", true)
}