import { vModelText as _vModelText, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["value", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.$props.disabled)
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 0,
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
          class: _normalizeClass([_ctx.$props.class, "ab-input"]),
          id: _ctx.$props.id,
          ref: "inputRef"
        }, null, 10, _hoisted_1)), [
          [_directive_maska, '(###)-###-####'],
          [_vModelText, _ctx.value]
        ])
      : _createCommentVNode("", true),
    (_ctx.$props.disabled)
      ? (_openBlock(), _createElementBlock("input", {
          key: 1,
          class: _normalizeClass([_ctx.$props.class, "ab-input"]),
          value: _ctx.disabledDisplay,
          id: _ctx.$props.id,
          disabled: ""
        }, null, 10, _hoisted_2))
      : _createCommentVNode("", true)
  ], 64))
}